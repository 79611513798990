<template>
  <div>
    <settings-table-sidebar />
    <new-appointment-sidebar />
    <comment-sidebar />
    <!-- Totalizadores -->
    <customer-totalizer :totalizer="totalizer" :filterStatus="filterByStatus" />

    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <!-- Filtros -->
      <customers-list-filters
        ref="filters"
        :spinnerActive="loading"
        :search="search"
      />

      <div class="m-md-2 mb-2 mx-2 mt-0">
        <!-- Table Top -->
        <b-row class="search-buttons-section">
          <!-- Per Page -->
          <b-col
            cols="12"
            md="4"
            class="d-flex align-items-center justify-content-start"
          >
            <label class="mb-0">Exibir</label>
            <v-select
              v-model="itemsPerPage"
              dir="ltr"
              :options="itemsPerPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
              @input="search(currentPage)"
            >
              <template #no-options="{ search, searching, loading }">
                Sem resultados
              </template>
            </v-select>
            <label class="mb-0">itens</label>
          </b-col>
          <!-- Search -->
          <b-col cols="12" md="8">
            <div
              class="d-flex align-items-center justify-content-end mb-1 mb-md-0 flex-wrap-reverse flex-md-wrap"
            >
              <b-button
                class="mr-0 mr-md-1 mb-md-0 mb-1 search-button"
                variant="outline-primary"
                @click="settings_table_modal"
              >
                <span class="align-middle">Personalizar tabela</span>
              </b-button>
              <b-button
                variant="outline-secondary"
                @click.prevent="clearSearch"
                class="mr-0 mr-md-1 mb-md-0 mb-1 search-button"
                :disabled="loading"
              >
                Limpar filtros
              </b-button>
              <b-button
                variant="primary"
                @click.prevent="search(1)"
                :disabled="loading"
                class="search-button mb-md-0 mb-1"
              >
                <b-spinner v-if="loading" small />
                <feather-icon v-else icon="SearchIcon" class="mr-50" />
                <span>
                  {{ loading ? "Aguarde..." : "Pesquisar" }}
                </span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>
      <b-table
        hover
        class="position-relative text-center lead-table"
        :items="items"
        responsive
        :fields="tableColumns"
        show-empty
        empty-text="Não foram encontrados clientes para esta busca"
        :busy="loading"
        :primary-key="'customer_id'"
        :no-local-sorting="true"
        :sort-by="sortField"
        @sort-changed="sort"
      >
        <!-- Column: Nome e telefone -->
        <template #cell(customer_name)="data">
          <div class="name-content">
            <b-badge
              variant="lead-name"
              class="name"
              @click="goToLeadDetails(data.item.customer_id)"
            >
              <div
                class="align-text-right text-capitalize text-wrap"
                style="margin-bottom: 4px"
              >
                {{ data.item.customer_name }}
              </div>
              <div v-if="data.item.cell_phone" class="align-text-top phone">
                {{ data.item.cell_phone | formatPhone }}
              </div>
            </b-badge>
            <b-badge
              variant="lead-name"
              class="whats"
              @click="openWhatsapp(data.item.cell_phone)"
            >
              <img src="~@/assets/images/svg/icn_whats.svg" width="15" />
            </b-badge>
          </div>
        </template>

        <!-- Column: Status -->
        <template #cell(customer_status_name)="data">
          <div class="text-center">
            <b-badge
              pill
              variant="support-user"
              v-if="
                data.item.customer_status_id == status.CUSTOMER_STATUS_SUPPORT
              "
            >
              <img src="~@/assets/images/svg/icn_support.svg" width="10" />
              {{ data.item.customer_status_name }}
            </b-badge>
            <b-badge
              pill
              variant="basic_sub-user"
              v-else-if="
                data.item.customer_status_id == status.CUSTOMER_STATUS_BASIC_SUB
              "
            >
              <img src="~@/assets/images/svg/icn_user_disable.svg" width="10" />
              {{ data.item.customer_status_name }}
            </b-badge>
            <b-badge
              pill
              variant="essential_sub-user"
              v-else-if="
                data.item.customer_status_id ==
                status.CUSTOMER_STATUS_ESSENTIAL_SUB
              "
            >
              <img src="~@/assets/images/svg/icn_essential.svg" width="12" />
              {{ data.item.customer_status_name }}
            </b-badge>
            <b-badge
              pill
              variant="elite_sub-user"
              v-else-if="
                data.item.customer_status_id == status.CUSTOMER_STATUS_ELITE_SUB
              "
            >
              <img src="~@/assets/images/svg/icn_premium.svg" width="12" />
              {{ data.item.customer_status_name }}
            </b-badge>
            <b-badge
              pill
              variant="done_ep-user"
              v-else-if="
                data.item.customer_status_id == status.CUSTOMER_STATUS_EP
              "
            >
              <img
                src="~@/assets/images/svg/icn_entrevistapaga_70.svg"
                width="12"
              />
              {{ data.item.customer_status_name }}
            </b-badge>
            <b-badge
              pill
              variant="done_financial_solution-user"
              v-else-if="
                data.item.customer_status_id ==
                status.CUSTOMER_STATUS_FINANCIAL_SOLUTION
              "
            >
              {{ data.item.customer_status_name }}
            </b-badge>

            <b-badge
              pill
              variant="canceled_subscription-user"
              v-else-if="
                data.item.customer_status_id ==
                status.CUSTOMER_STATUS_CANCELED_SUBSCRIPTION
              "
            >
              {{ data.item.customer_status_name }}
            </b-badge>

            <b-badge
              pill
              variant="gestao-user"
              v-else-if="
                data.item.customer_status_id ==
                status.CUSTOMER_STATUS_GESTAO
              "
            >
              {{ data.item.customer_status_name }}
            </b-badge>

            <b-badge pill variant="undefined-user" v-else>
              {{ data.item.customer_status_name }}
              <span v-if="!data.item.customer_status_name">
                A classificar
              </span>
            </b-badge>
          </div>
        </template>

        <!-- Column: Dias desde a última reunião -->
        <template #cell(last_interview_days)="data">
          <div class="text-wrap text-center">
            <span class="align-text-top text-capitalize">{{
              data.item.last_interview_days
            }}</span>
          </div>
        </template>

        <!-- Column: Data de ligação -->
        <template #cell(call_reminder)="data">
          <div class="text-wrap">
            <b-form-input
              @blur="
                saveCallReminder(data.item.call_reminder, data.item.customer_id)
              "
              class="form-control"
              type="datetime-local"
              v-model="data.item.call_reminder"
            />
          </div>
        </template>
        
        <!-- Column: VIP -->
        <template #cell(is_vip)="data">
          <div class="text-wrap">
            <img
              v-if="data.item.is_vip"
              class="img_icon"
              src="~@/assets/images/svg/icn_premium.svg"
            />
          </div>
        </template>

        <!-- Column: Renda -->
        <template #cell(cash_flow)="data">
          <div class="text-wrap">
            <span class="align-text-top text-capitalize">{{
              data.item.cash_flow | toCurrency
            }}</span>
          </div>
        </template>

        <!-- Column: Poupa -->
        <template #cell(monthly_savings)="data">
          <div class="text-wrap">
            <span class="align-text-top text-capitalize">{{
              data.item.monthly_savings | toCurrency
            }}</span>
          </div>
        </template>
        <!-- Column: Objetivo -->
        <template #cell(goals)="data">
          <div
            v-for="(goal, index) in splitGoals(data.item.goals)"
            :key="`goal-${index}`"
          >
            <details>
              <summary style="width: max-content; font-weight: bold">
                {{ goal | leadGoalTitle }}
              </summary>
              <p style="">{{ goal | leadGoal }}</p>
            </details>
          </div>
        </template>

        <!-- Column: Consultor responsável -->
        <template #cell(consultant_name)="data">
          <div class="text-wrap">
            <span class="align-text-top text-capitalize">{{
              data.item.consultant_name
            }}</span>
          </div>
        </template>

        <!-- Column: Franquia -->
        <template #cell(franchise_name)="data">
          <div class="text-wrap">
            <span class="align-text-top text-capitalize">{{
              data.item.franchise_name
            }}</span>
          </div>
        </template>

        <!-- Column: E-mail -->
        <template #cell(email)="data">
          <div class="text-wrap">
            <b-button variant="link" @click="sendEmail(data.item.email)">
              {{ data.item.email }}
            </b-button>
          </div>
        </template>

        <!-- Column: Aniversário -->
        <template #cell(anniversary_date)="data">
          <div class="text-wrap">
            <span class="align-text-top text-capitalize">{{
              data.item.anniversary_date
            }}</span>
          </div>
        </template>

        <template #cell(last_interview_date)="data">
          <div class="text-wrap">
            <span class="align-text-top text-capitalize">{{
              data.item.last_interview_date | dateTime
            }}</span>
          </div>
        </template>

        <!-- Column: Cliente a partir de -->
        <template #cell(became_customer_at)="data">
          <div class="text-wrap text-center">
            <span class="align-text-top text-capitalize">{{
              (data.item.became_customer_at || data.item.created_at) | dateTime
            }}</span>
          </div>
        </template>

        <!-- Column: ações -->
        <template #cell(actions)="data">
          <div class="text-wrap">
            <b-button-group>
              <template v-if="canCreateAppointment && data.item.can_create_appointment">
                <b-button
                  :id="`customer-new-appointment` + data.item.customer_id"
                  class="btn-icon mr-1"
                  variant="flat"
                  style="padding: 0"
                  @click="createAppointment(data.item.customer_id)"
                >
                  <img
                    class="img_action"
                    src="~@/assets/images/svg/icn_calendar.svg"
                  />
                </b-button>
                <b-tooltip
                  :target="`customer-new-appointment` + data.item.customer_id"
                  triggers="hover"
                >
                  Agendar reunião
                </b-tooltip>
              </template>
              <b-button
                v-if="canUpdateCustomer"
                :id="`customer-new-comment` + data.item.customer_id"
                class="btn-icon"
                variant="flat"
                style="padding: 0; color: white"
                @click="addComment(data.item.customer_id)"
              >
                <feather-icon icon="MessageSquareIcon" />
              </b-button>
              <b-tooltip
                :target="`customer-new-comment` + data.item.customer_id"
                triggers="hover"
              >
                Adicionar comentário
              </b-tooltip>
            </b-button-group>
          </div>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted"
              >Exibindo {{ pageItemsFrom }} a {{ pageItemsTo }} de
              {{ totalItems }} itens</span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalItems"
              :per-page="itemsPerPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
              @change="search"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import _ from "lodash";
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BCardHeader,
  BCardBody,
  BSpinner,
  BCardText,
  BMediaAside,
  BMediaBody,
  BCardTitle,
  BButtonGroup,
  BTooltip,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { mapActions, mapGetters, mapMutations } from "vuex";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import { LEAD_TYPE_PCL } from "@/constants/lead";
import SettingsTableSidebar from "../components/SettingsTableSidebar";
import CustomerTotalizer from "../components/CustomersTotalizer";
import CustomersListFilters from "../components/CustomersListFilters";
import NewAppointmentSidebar from "@/modules/appointment/components/NewAppointmentSidebar";
import CommentSidebar from "@/modules/shared/components/CommentSidebar";
import * as structureOptions from "@/constants/structure_options";
import * as customerStatus from "@/constants/customers_status";
import * as sharedTypes from "@/modules/shared/store/types";
import * as types from "../store/types";
import { getRangeDates } from "@/helpers/date_picker";
import * as appointmentTypes from "@/modules/appointment/store/types";
import useAppConfig from "@core/app-config/useAppConfig";
import { onlyDigits } from "@/helpers/converters";
import {
  CUSTOMER_RESOURCE,
  APPOINTMENT,
  CREATE_ACTION,
  UPDATE_ACTION
} from "@/constants/resources";


export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BSpinner,
    BCardText,
    BCardTitle,
    BMediaAside,
    BMediaBody,
    BButtonGroup,
    SettingsTableSidebar,
    vSelect,
    NewAppointmentSidebar,
    CustomerTotalizer,
    CustomersListFilters,
    BTooltip,
    CommentSidebar,
  },
  setup() {
    const { skin } = useAppConfig();

    return { toast: useToast(), skin };
  },
  data() {
    return {
      loading: false,
      leadType: LEAD_TYPE_PCL,
      appifyUrl: process.env.VUE_APP_URL,
      currentPage: 1,
      itemsPerPage: 1,
      searchQuery: "",
      ic_bt_fire: 1,
      structureOption: structureOptions.STRUCTURE,
      sortField: null,
      order: undefined,
    };
  },
  computed: {
    ...mapGetters({
      searchResult: types.CUSTOMERS_SEARCH_RESULT,
      searchTotalizers: types.CUSTOMERS_SEARCH_TOTALIZERS,
      initialItemsPerPage: sharedTypes.INITIAL_ITEMS_PER_PAGE,
      itemsPerPageOptions: sharedTypes.ITEMS_PER_PAGE_OPTIONS,
      settingsTableCustomer: types.SETTINGS_TABLE_CUSTOMERS,
      tableColumns: types.CUSTOMERS_TABLE_COLUMNS,
    }),
    items: function () {
      return this.searchResult?.data || [];
    },
    totalItems: function () {
      return this.searchResult?.total || 0;
    },
    canUpdateCustomer: function() {
      return this.$can(UPDATE_ACTION, CUSTOMER_RESOURCE)
    },
    canCreateAppointment: function() {
      return this.$can(CREATE_ACTION, APPOINTMENT)
    },
    pageItemsFrom: function () {
      return this.searchResult?.from || 0;
    },
    pageItemsTo: function () {
      return this.searchResult?.to || 0;
    },
    totalizer: function () {
      if (this.searchTotalizers) {
        var totalizerObj = {};
        this.searchTotalizers.forEach((item) => {
          totalizerObj[item.customer_status_id] = item.total;
        });
        var sumTotal = Object.values(totalizerObj).reduce(
          (acc, curr) => acc + curr,
          0
        );
        totalizerObj.total_sum = sumTotal;
        return totalizerObj || {};
      } else {
        return {};
      }
    },
    status: function () {
      return customerStatus;
    },
  },
  mounted() {
    this.itemsPerPage = this.initialItemsPerPage;
    this.getSettingsTableCustomers()
      .then((response) => {
        this.search();
        this.hideTableColumns();
      })
      .catch((error) => {
        this.toast({
          component: ToastificationContent,
          props: {
            title: "Oops!",
            text: "Ocorreu um erro ao carregar os status para seleção. Entre em contato com o setor de TI.",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
  },
  methods: {
    ...mapActions({
      searchCustomers: types.SEARCH_CUSTOMERS,
      openSettingsTableSidebar: types.OPEN_SETTINGS_TABLE_SIDEBAR,
      getSettingsTableCustomers: types.GET_SETTINGS_TABLE_CUSTOMERS,
      openNewAppointmentSidebar: appointmentTypes.OPEN_NEW_APPOINTMENT_SIDEBAR,
      setCallReminder: types.SET_CALL_REMINDER,
      openCommentSidebar: sharedTypes.OPEN_COMMENT_SIDEBAR,
      saveComment: sharedTypes.SAVE_CUSTOMER_COMMENT,
    }),
    ...mapMutations({
      hideTableColumns: types.HIDE_COLUMNS,
    }),
    settings_table_modal() {
      this.openSettingsTableSidebar({
        currentPage: this.currentPage ? this.currentPage : 1,
      });
    },
    search(currentPage) {
      if (!this.$refs.filters) return;
      this.loading = true;
      const {
        name,
        phone,
        cpf,
        goal,
        status,
        consultant,
        occupation,
        franchise,
        callReminder,
        structureOption,
        recommendedBy,
        monthlySavings,
        cashFlow,
        isVip
      } = this.$refs.filters;
      const call_reminder = getRangeDates(callReminder);
      this.searchCustomers({
        name,
        phone,
        cpf: onlyDigits(cpf),
        goal,
        status,
        consultant,
        occupation,
        franchise,
        call_reminder_start: call_reminder.start,
        call_reminder_end: call_reminder.end,
        structure_option: structureOption,
        customer_reference: recommendedBy,
        monthly_savings: monthlySavings,
        cash_flow: cashFlow,
        is_vip: isVip,
        order: this.order,
        limit: this.itemsPerPage,
        currentPage: Number.isInteger(currentPage)
          ? currentPage
          : this.currentPage,
      })
        .catch((error) => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Oops!",
              text: "Ocorreu um erro ao buscar os clientes. Entre em contato com o setor de TI.",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    setStatus(data_current, new_status) {
      if (data_current.customer_status_id == new_status) {
        new_status = customerStatus.CUSTOMER_STATUS_UNDEFINED;
      } else if (
        data_current.customer_status_id == customerStatus.CUSTOMER_STATUS_EA ||
        data_current.customer_status_id == customerStatus.CUSTOMER_STATUS_EF
      ) {
        this.$swal({
          title: "Confirmação",
          text: `Deseja realmente alterar o status?`,
          icon: "warning",
          background: `${this.skin === "dark" ? "#283046" : ""}`,
          showCancelButton: true,
          confirmButtonText: "Alterar",
          cancelButtonText: "Cancelar",
          customClass: {
            confirmButton: "btn btn-primary",
            cancelButton: "btn btn-secondary ml-1",
            htmlContainer: `${this.skin === "dark" ? "text-white" : ""}`,
          },
        }).then((result) => {
          if (result.value) {
            this.setLeadStatus(data_current.customer_id, new_status);
          }
        });
      } else {
        this.setLeadStatus(data_current.customer_id, new_status);
      }
    },
    setLeadStatus(customer_id, status_id) {
      this.setCustomerLeadStatus({
        customer_id,
        status_id,
      })
        .then((response) => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Sucesso!",
              text: "Atualizado com sucesso.",
              icon: "CoffeeIcon",
              variant: "success",
            },
          });
          this.search(this.currentPage);
        })
        .catch(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Oops!",
              text: "Ocorreu um erro ao atualizar o status. Entre em contato com o setor de TI.",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        });
    },
    sendEmail(email) {
      location.href = "mailto:" + email;
    },
    createAppointment(customer_id) {
      this.openNewAppointmentSidebar({
        customer_id,
        saveAction: () => {},
      });
    },
    openWhatsapp(cell_phone) {
      setTimeout(() => {
        window.open("https://wa.me/" + cell_phone, "_blank").focus();
      });
    },
    editCustomer(id) {
      this.openNewLeadSidebar({
        id,
        leadType: LEAD_TYPE_PCL,
        saveAction: () => {
          this.search(this.currentPage);
        },
      });
    },
    goToLeadDetails(customer_id) {
      var routeData = this.$router.resolve({
        name: "customer-profile",
        params: { id: customer_id },
      });
      setTimeout(() => {
        window.open(routeData.href, "_blank");
      });
    },
    sort(event) {
      //clear in third click
      if (!event.sortDesc && this.sortField == event.sortBy) {
        this.order = undefined;
        this.sortField = null;
      } else {
        var direction = event.sortDesc ? " DESC" : " ASC";
        this.order = event.sortBy + direction;
        this.sortField = event.sortBy;
      }
      this.search();
    },
    saveCallReminder(date, customer_id) {
      this.setCallReminder({
        date,
        customer_id,
      })
        .then((response) => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Sucesso!",
              text: "Atualizado com sucesso.",
              icon: "CoffeeIcon",
              variant: "success",
            },
          });
          this.search(this.currentPage);
        })
        .catch(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Oops!",
              text: "Ocorreu um erro ao escolher a data para lembrar. Entre em contato com o setor de TI.",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        });
    },
    filterByStatus(status) {
      this.$refs.filters.status = [status];
      this.search(1);
    },
    async saveLeadComment(payload) {
      return this.saveComment({
        customer_id: payload.id,
        comment: payload.comment,
      });
    },
    addComment(customer_id) {
      this.openCommentSidebar({
        id: customer_id,
        title: "Adicionar comentário",
        saveAction: this.saveLeadComment,
      });
    },
    clearSearch() {
      this.$refs.filters.name = undefined;
      this.$refs.filters.phone = undefined;
      this.$refs.filters.cpf = undefined;
      this.$refs.filters.goal = undefined;
      this.$refs.filters.status = undefined;
      this.$refs.filters.consultant = undefined;
      this.$refs.filters.occupation = undefined;
      this.$refs.filters.callReminder = undefined;
      this.$refs.filters.structureOption = structureOptions.OWN;
      this.$refs.filters.callReminder = undefined;
      this.$refs.filters.franchise = undefined;
      this.$refs.filters.isVip = false;
      (this.$refs.filters.monthlySavings = [0, 20000]),
        (this.$refs.filters.cashFlow = [0, 20000]),
        (this.$refs.filters.recommendedBy = undefined),
        this.search(1);
    },
    splitGoals(value) {
      if (value != null) {
        return value.split(";");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
.img_action {
  width: 16px;
  height: 16px;
}

#buttons {
  border: 0px;
}

input[type="datetime-local"] {
  width: 180px;
}

@media (max-width: 768px) {
  .search-buttons-section {
    flex-direction: column-reverse;
  }

  .search-button {
    width: 100%;
  }
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

table #buttons {
  width: 40px;
}
.b-table-sticky-column:last-child {
  right: 0;
}
.lead-table thead th {
  white-space: nowrap !important;
}
</style>
